import * as React from "react"
import { Link } from "gatsby"
import { Box, Flex, Text, Image } from "theme-ui"
import { Tags } from "../modern/buttons"
import topPostImg from "../../images/home_post.png"

export const BlogCardTop = ({ node }) => {
  const title = node.frontmatter.shortTitle || node.frontmatter.title
  const tags = node.frontmatter.tags
  return (
    <Flex
      sx={{
        flexDirection: `row`,
        flexWrap: `wrap`,
        alignItems: `center`,
        alignContent: `center`,
        gap: `5em`,
      }}
    >
      <Flex
        sx={{
          flexGrow: 1,
          flexBasis: `40em`,
          borderTop: [`none`, `solid .1rem black`],
          flexDirection: `column`,
        }}
        gap={0}
      >
        <Link to={node.fields.slug}>
          <Text
            as="div"
            sx={{
              color: `black`,
              fontSize: [`16px`],
              mt: [0, `15px`],
            }}
          >
            {node.fields.date}
          </Text>
          <Text
            as="div"
            sx={{
              color: `black`,
              fontSize: [`34px`, `66px`],
              lineHeight: [1],
              fontWeight: 700,
              textTransform: `uppercase`,
              mt: [`20px`, `30px`, `10px`],
              minHeight: [`100px`],
            }}
          >
            {title}
          </Text>
          <Text
            as="div"
            sx={{
              color: `black`,
              fontSize: [`27px`, `27px`, `18px`],
              mt: [`35px`],
            }}
            dangerouslySetInnerHTML={{
              __html: node.frontmatter.description || node.excerpt,
            }}
          />
        </Link>

        <Box
          sx={{
            marginTop: `3em`,
            fontSize: `0.9em`,
          }}
        >
          <Tags
            tags={tags.map(tag => ({ tag: tag, link: `/blog/tags/${tag}` }))}
          />
        </Box>
      </Flex>
      <Box
        sx={{
          marginX: `auto`,
          maxWidth: `30em`,
        }}
      >
        <Image src={topPostImg} />
      </Box>
    </Flex>
  )
}
