import * as React from "react"
import { Box, Grid, Image } from "theme-ui"
import pattern1 from "../../images/post_pattern1.png"
import pattern2 from "../../images/post_pattern2.png"
import pattern3 from "../../images/post_pattern3.png"
import { BlogCard } from "./blog-card"

// Display a grid of blog posts, optionally with patterns inserted
// periodically.
export function BlogPostsGrid({ posts, showPatterns }) {
  return (
    <Grid
      sx={{
        mt: [`20px`],
        mb: [`40px`],
        rowGap: [`60px`, `40px`],
        columnGap: [0, `30px`, `5%`],
      }}
      columns={[1, 3]}
    >
      {posts.map((post, i) => {
        const patterns = [pattern1, pattern2, pattern3]
        const period = 6
        const start = 3
        const pattern = patterns[((i + start) / period) % patterns.length]
        if (showPatterns && (i + start) % period === 0) {
          return (
            <React.Fragment key={i}>
              <Box>
                <Image src={pattern} alt="" />
              </Box>
              <BlogCard node={post.node} />
            </React.Fragment>
          )
        } else {
          return <BlogCard key={i} node={post.node} />
        }
      })}
    </Grid>
  )
}
