import * as React from "react"
import { graphql } from "gatsby"
import { Box, Flex, Paragraph, Input, Text } from "theme-ui"
import { Tags } from "../components/modern/buttons"
import headingImage from "../images/img18.svg"
import modusLogo from "../images/logo_modus_textless.svg"
import sadTweagFace from "../images/sad_tweag_face.svg"
import { useDebouncedCallback } from "use-debounce"
import {
  PaddedContent,
  PageHeading,
  PageWrapper,
} from "../components/modern/layout"
import { SimpleButton } from "../components/modern/buttons"
import { BlogPostsGrid } from "../components/blog/posts-grid"
import { BlogCardTop } from "../components/blog/blog-card-top"

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { key: { ne: "group" } } }
      sort: { fields: [fields___slug], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            date(formatString: "D MMMM YYYY")
            slug
          }
          frontmatter {
            title
            shortTitle
            description
            tags
          }
        }
      }
    }
  }
`

const ModusBlogBanner = () => (
  <PaddedContent background="lightGrey">
    <Flex
      sx={{
        gap: `3em`,
        justifyContent: `center`,
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${modusLogo})`,
          backgroundSize: `contain`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `left`,
          width: `4em`,
          flexGrow: 0,
          flexShrink: 0,
        }}
      ></Box>
      <Box
        sx={{
          maxWidth: `60em`,
        }}
      >
        <Paragraph
          sx={{
            marginBottom: `2em`,
          }}
        >
          While the Tweag blog explores advanced tech, the{` `}
          <strong> Modus blog</strong> explores its real-world impact. Together,
          they pack potent insights for technology and business leaders alike.
        </Paragraph>
        <SimpleButton
          label="Explore the Modus Blog"
          url="https://moduscreate.com/blog/"
          theme={{
            backgroundColor: `black`,
            textColor: `white`,
          }}
        />
      </Box>
    </Flex>
  </PaddedContent>
)

const NoSearchResultsBanner = () => (
  <PaddedContent background="yellow">
    <Flex
      sx={{
        gap: `3em`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${sadTweagFace})`,
          backgroundSize: `contain`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `left`,
          width: `4em`,
          height: `4em`,
          flexGrow: 0,
          flexShrink: 0,
        }}
      ></Box>
      <Box
        sx={{
          maxWidth: `60em`,
        }}
      >
        <Paragraph>
          It seems no post in our blog matches your request... until we write
          it!
        </Paragraph>
      </Box>
    </Flex>
  </PaddedContent>
)

const SearchInput = ({ onSearch }) => (
  <Flex
    sx={{
      gap: `.5em`,
      alignItems: `center`,
    }}
  >
    <Text
      sx={{
        fontFamily: `icomoon`,
      }}
    >
      
    </Text>
    <Input
      sx={{
        marginY: `1em`,
        border: `none`,
      }}
      placeholder="Search by title"
      type="text"
      onChange={onSearch}
    />
  </Flex>
)

const BlogIndex = ({ data }) => {
  const allPosts = data.allMarkdownRemark.edges
  const [topPost, ...posts] = allPosts
  const [searchPattern, setSearchPattern] = React.useState(``)

  // Map from tag names to number of occurrences in blog posts
  const allTagsCounted: Map<string, number> = React.useMemo(
    () =>
      allPosts.reduce((acc, post) => {
        const tags: string[] = post.node.frontmatter.tags
        tags.forEach(tag => acc.set(tag, 1 + (acc.get(tag) || 0)))
        return acc
      }, new Map()),
    [allPosts]
  )

  // Sorted list of tag names and number of occurrences, most frequent first
  const allTagsByFrequency: [string, number][] = React.useMemo(
    () => Array.from(allTagsCounted.entries()).sort((a, b) => b[1] - a[1]),
    [allTagsCounted]
  )

  // First 12 tags, provided with their associated link
  const mostFrequentTags = React.useMemo(
    () =>
      allTagsByFrequency
        .slice(0, 12)
        .map(([tag, _]) => ({ tag, link: `/blog/tags/${tag}` })),
    [allTagsByFrequency]
  )

  const handleChange = useDebouncedCallback(
    e => setSearchPattern(e.target.value),
    300
  )

  const showAllPosts = searchPattern === ``

  const searchResults = React.useMemo(
    () =>
      showAllPosts
        ? []
        : allPosts.filter(post => {
            const title = post.node.frontmatter.title
            return title.toLowerCase().includes(searchPattern.toLowerCase())
          }),
    [searchPattern, allPosts]
  )

  return (
    <PageWrapper title="Engineering blog" pathname="/blog">
      <PageHeading title="Engineering blog" imageSrc={headingImage}>
        <Box sx={{ fontSize: `.9rem` }}>
          <Tags tags={mostFrequentTags} />
        </Box>
        <SearchInput onSearch={handleChange} />
      </PageHeading>
      {showAllPosts ? <ModusBlogBanner /> : null}
      {!showAllPosts && searchResults.length === 0 ? (
        <NoSearchResultsBanner />
      ) : null}
      <PaddedContent
        customSx={{
          maxWidth: `90em`,
          marginX: `auto`,
        }}
      >
        <Flex
          sx={{
            flexDirection: `column`,
            gap: `5em`,
            alignItems: `stretch`,
            mt: [`20px`, `60px`],
          }}
        >
          {showAllPosts ? (
            <>
              <BlogCardTop node={topPost.node} />
              <BlogPostsGrid posts={posts} showPatterns={true} />
            </>
          ) : (
            <BlogPostsGrid posts={searchResults} showPatterns={false} />
          )}
        </Flex>
      </PaddedContent>
    </PageWrapper>
  )
}

export default BlogIndex
